import axios from "axios";

export const clientGet = async (url, header = {}) => {
  try {
    console.log("Request url : ", url);
    const response = await axios.get(url);
    console.log("Request result : ", response.status);
    return response;
  } catch (error) {
    console.log("Request result : ", error.response.status);
    return error.response;
  }
};

export const clientPost = async (url, body = {}, header = {}) => {
  try {
    console.log("Request url : ", url);
    header != null && console.log("Request header : ", header);
    header != null && console.log("Request body : ", body);
    const response = await axios.post(url, body, header);
    console.log("Request result : ", response.status);
    return response;
  } catch (error) {
    console.log("Request result : ", error);
    return null;
  }
};
