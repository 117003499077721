import { StyleSheet } from "react-native";
import { responsiveWidth } from "react-native-responsive-dimensions";

export const StyleBillPaymentReminder = StyleSheet.create({
  a: {
    fontWeight: "300",
    color: "#FF3366", // make links coloured pink
  },
  p: {
    textAlign: "justify",
  },
  buttonM: {
    marginTop: 20,
    borderRadius: 20,
    padding: 10,
    paddingHorizontal: 30,
    paddingVertical: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#0344f8",
  },
  hddatamaintwo: {
    padding: 15,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop: 3,
    marginBottom: 3,
    backgroundColor: "#FFF",
    width: responsiveWidth(96.7),
  },
  modalView: {
    marginTop: 200,
    backgroundColor: "#eaf7f8",
    borderRadius: 20,
    padding: 15,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  textStyleclose: {
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 18,
  },
  yesB: {
    alignSelf: "flex-end",
    backgroundColor: "#f8e807",
    borderRadius: 15,
    color: "#080808",
    fontWeight: "bold",
    textAlign: "center",
    paddingVertical: 8,
    paddingHorizontal: 10,
    textAlign: "right",
    marginRight: 20,
    marginLeft: 25,
    fontSize: 14,
  },
  yesBs: {
    alignSelf: "flex-end",
    backgroundColor: "#FFFFBD",
    borderRadius: 15,
    color: "#080808",
    fontWeight: "bold",
    textAlign: "center",
    paddingVertical: 8,
    paddingHorizontal: 10,
    textAlign: "right",
    marginRight: 20,
    marginLeft: 25,
    fontSize: 14,
    fontWeight: "bold",
  },
  modal: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00BCD4",
    height: 300,
    width: "80%",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#fff",
    marginTop: 80,
    marginLeft: 40,
  },
  text: {
    color: "#3f2949",
    marginTop: 10,
  },
  span: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  rmore: { fontSize: 16, color: "#0000A0", marginTop: 5 },
  rless: { fontSize: 16, color: "#0000A0", marginTop: 5 },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0373BB",
  },
  textTile: {
    fontSize: 15,
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    color: "#008000",
  },
  CaseTypeNoTile: {
    fontSize: 14,
    alignItems: "center",
    justifyContent: "center",
    width: 99,
  },
  textTilecln: {
    fontSize: 15,
    marginLeft: 100,
    paddingLeft: 80,
    alignItems: "center",
    width: 200,
    color: "#f00",
  },
  textDescription: {
    paddingTop: 3,
    width: responsiveWidth(92),
    textAlign: "justify",
  },
  textDescriptiona: {
    textAlignVertical: "center",
    textAlign: "center",
  },
  textParties: {
    fontSize: 8,
    paddingTop: 8,
  },
  titleText: {
    fontSize: 30,
    alignItems: "center",
    justifyContent: "center",
  },
  LawyerInfo: {
    padding: 5,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
    marginTop: 5,
  },
  LawyerInfoText: {
    fontSize: 16,
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    textAlign: "center",
    width: "100%",
  },
  button: {
    alignItems: "center",
    backgroundColor: "#419641",
    width: 80,
    height: 37,
    padding: 3,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
    marginTop: 10,
    marginLeft: 10,
  },
  buttonText: {
    fontSize: 20,
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  input: {
    width: 150,
    fontSize: 22,
    height: 38,
    paddingLeft: 10,
    borderWidth: 1,
    borderColor: "white",
    marginVertical: 10,
    borderRadius: 6,
  },
  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8,
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff",
  },
  title: {
    fontSize: 24,
  },
  SectionHeaderStyle: {
    backgroundColor: "#CDDC89",
    fontSize: 20,
    padding: 5,
    color: "#fff",
  },
  SectionListItemStyle: {
    fontSize: 15,
    padding: 15,
    color: "#000",
    backgroundColor: "#F5F5F5",
  },
  hddata: {
    padding: 5,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop: 5,
    marginBottom: 0,
    marginLeft: 7,
    marginRight: 7,
    backgroundColor: "#FFFFBD",
     height:'auto'
  },
  hddatanotview: {
    padding: 5,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop: 5,
    marginBottom: 10,
    marginLeft: 7,
    marginRight: 7,
    backgroundColor: "#f8e807",
     height:'auto'
  },
  dateText: {
    color: "#008000",
    textAlign: 'center',
    marginBottom: 0
  },
  hddataText: {
    fontSize: 20,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },
  hddataResult: {
    padding: 5,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 6,
    marginTop: 5,
    marginLeft: 7,
    marginRight: 7,
    backgroundColor: "#FFFFBD",
  },
  totalfound: {
    fontSize: 14,
    alignItems: "center",
    color: "#fff",
  },
  noItems: {
    fontSize: 24,
    alignItems: "center",
    justifyContent: "center",
    color: "#FFF",
    paddingLeft: 90,
    width: responsiveWidth(92),
    paddingTop: 120,
    paddingBottom: 20,
    textShadowColor: "rgba(0, 0, 0,0.9)",
    textShadowOffset: { width: -1, height: 2 },
    textShadowRadius: 15,
  },
  errorColor: {
    color: "coral",
  },
  isDraft: {
    fontSize: 20,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    marginLeft: 10,
  },
  CaseResultDate: {
    padding: 5,
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 6,
    marginLeft: 5,
    marginRight: 6,
    backgroundColor: "#fff",
    color: "#000",
    alignItems: "center",
    justifyContent: "center",
  },
  get_date: {
    fontSize: 17,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },
  last_datetime: {
    fontSize: 14,
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },
  fadingContainer: {
    backgroundColor: "blueviolet",
    borderRadius: 4,
    margin: 20,
  },
  fadingText: {
    fontSize: 16,
    textAlign: "center",
    color: "#fff",
    paddingVertical: 5,
    paddingHorizontal: 25,
  },
  textStyle: {
    fontSize: 14,
    textAlign: "center",
    width: responsiveWidth(92),
    marginBottom: 10
  },
  textStyleM: {
    fontSize: 18,
    textAlign: "justify",
    width: responsiveWidth(92),
  },
});
