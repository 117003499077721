//https://colorhunt.co/palette/87a2ffc4d7ffffd7c4fff4b5

export const TinColor = "#FFF4B5";
export const TextColor = "#000000";
export const Primary = "#87A2FF";
export const Background = "#C4D7FF";
export const buttonColor1 = ['#68bac0', '#5B80AF', '#44648E'];
export const buttonColor2 = ['#EAFAF9', '#c1edfa', '#a0d4f8'];
export const buttonColor3 = ["#FFFFB7", "#FFEA61", "#FFDD3C"]
export const buttonColor4 = ['#FFFF', '#FFFFdd', '#FFFFBD']
export const color1= ["#b47af3", "#a45af3", "#6a11cb"]
export const color2= ["#87da87", "#419641", "#1a7c1a"]
export const color3= ["#FFFF", "#FFFFdd", "#FFFFBD"]
export const color4= ["#f8d7ab", "#f3bc75", "#f29111"]
export const color5= ["#f4f4f4", "#ececec", "#969696"]
export const color6= ["#FF0000", "#FF0000", "#FF0000"]
export const color7= ["#419641", "#419641", "#419641"]