import { StyleSheet } from "react-native";
import { responsiveFontSize, responsiveWidth } from "react-native-responsive-dimensions";

export const StylePaymentStatement = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#0373BB",
    paddingHorizontal: 10,
  },
  searchContainer: {
    flexDirection: "row",
    justifyContent: 'space-around',
    marginTop: 10,
    width: 250
  },
  input: {
    width: "50%",
    fontSize: 22,
    height: 38,
    paddingLeft: 10,
    borderWidth: 1,
    borderColor: "white",
    marginVertical: 10,
    borderRadius: 6,
    color: "#fff",
  },
  searchButton: {
    marginLeft: -15,
    marginTop: 10,
  },
  lawyerinfo: {
    width: "100%",
    borderColor: "white",
    backgroundColor: "#f5f2e4",
    borderRadius: 5,
    padding: 10,
    marginVertical: 10,
  },
  lawyerInfoRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  lawyerinfoTextLabel: {
    fontSize: responsiveFontSize(2),
    color: "#000",
    lineHeight: 22,
    width: "100%",
        fontWeight: 'bold'
  },
  lawyerinfoTextValue: {
    marginStart: 10,
    fontSize: responsiveFontSize(1.65),
    color: "#000",
    flexWrap: 'wrap',
    width: "70%",
    fontWeight: '500'

  },
  message: {
    flex: 1,
    justifyContent: "center",
  },
  messageText: {
    color: "#fff",
    fontSize: 22,
  },
  infoCard: {
    width: "100%",
    borderColor: "white",
    backgroundColor: "#FFF4B5",
    borderRadius: 5,
    padding: 10,
    marginVertical: 5,
  },
  infoCardRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  infoCardTextLabel: {
    fontSize: responsiveFontSize(2.1),
    fontWeight: "400",
     width: "40%"
  },
  infoCardTextValue: {
    fontSize: responsiveFontSize(2.1),
    fontWeight: "800",
  },
  dateText: {
    color: "#008000",
    textAlign: 'center',
    marginBottom: -12,
  },
  textStyle: {
    fontSize: 14,
    textAlign: "center",
    width: responsiveWidth(92),
    marginBottom: 0,
  },
});
