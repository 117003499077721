import AsyncStorage from "@react-native-async-storage/async-storage";

export const GetUserInfo = async () => {
  let userInfo = JSON.parse((await AsyncStorage.getItem("userInfo")) || "{}");
  return userInfo;
};

export const SetUserInfo = async (user) => {
  await AsyncStorage.setItem("userInfo", JSON.stringify(user));
};
