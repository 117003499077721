import {
  View,
  Text,
  Modal,
  Pressable,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  Button,
  Image,
} from "react-native";
import React, { useRef } from "react";
import RenderHtml from "react-native-render-html";
import { responsiveWidth } from "react-native-responsive-dimensions";
import { StyleBillPaymentReminder } from "./BillPaymentReminder/StyleBillPaymentReminder";
import { useState } from "react";

const ModalIsDue = ({ modalVisible, message, onClose = () => {} }) => {
  // #region variable
  const mgs = `<div>Dear Sir/ Madam,</div>
  <p>You are kindly requested to pay your monthly Search List bill on time. 
  You can pay your bill via bKash/ Nagad Merchant No. <strong>01760 200 200 </strong>(Payment).</p>
  <div>Thank you, Siddique Enterprise.</div>`;
  const scrollListRefTop = useRef(null);
  // #endregion

  // #region state
  const [infoViewHeight, setInfoViewHeight] = useState(0);
  const [scrollTo, setScrollTo] = useState(false);
  const [scrollTopCount, setScrollTopCount] = useState(0);
  // #endregion

  // #region redux

  // #endregion

  // #region use effect

  // #endregion

  // #region method
  const handleInfoViewLayout = (event) => {
    const { height } = event.nativeEvent.layout;

    setInfoViewHeight(height);
    if (height > 380) {
      setScrollTo(true);
    }
    // setInfoViewHeight(height + infoViewHeight);
  };

  const handleScroll = (event) => {
    const scrollY = event.nativeEvent.contentOffset.y;
    setScrollTopCount(scrollY); // This updates with the scroll distance from the top

    if (scrollY > infoViewHeight - 352) {
      setScrollTo(false);
    } else {
      if (infoViewHeight > 380) {
        setScrollTo(true);
      }
    }
  };

  const scrollToBottom = () => {
    if (scrollListRefTop.current) {
      setScrollTopCount(scrollTopCount + 110);
      scrollListRefTop.current.scrollTo({
        y: scrollTopCount + 110,
        animated: true,
      });
    }
  };
  // #endregion

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        onClose(false);
      }}
    >
      <View style={[{ marginTop: 80, marginBottom: 60, marginHorizontal: 10 }]}>
        <View style={styles.modalView}>
          <View style={{ width: responsiveWidth(92) }}>
            <ScrollView
              style={[styles.textStyleM, { minHeight: 350, maxHeight: 350 }]}
              onScroll={handleScroll}
              ref={scrollListRefTop}
            >
              <View onLayout={handleInfoViewLayout}>
                <RenderHtml
                  style={[{ marginHorizontal: 20 }]}
                  source={{
                    html: message == "" ? mgs : message,
                  }}
                  tagsStyles={tagsStylesM}
                  contentWidth={responsiveWidth(92)}
                />
                <TouchableOpacity
                  style={[styles.buttonM]}
                  onPress={() => {
                    onClose(false);
                  }}
                >
                  <Text style={styles.textStyleclose}>Ok</Text>
                </TouchableOpacity>
              </View>
            </ScrollView>

            {scrollTo && (
              <Pressable
                onPress={() => {
                  scrollToBottom();
                }}
                style={[
                  {
                    alignSelf: "center",
                    alignItems: "center",
                    position: "absolute",
                    bottom: 0,
                    right: "10%", 
                    backgroundColor: "rgba(255,255,0, 0.8)",
                    marginBottom: 5,
                    borderRadius: 20,
                    borderColor: 'black',
                    borderWidth: 0.5
                  },
                ]}
              >
                {/* <Text>Reade More</Text> */}
                <Image
                  source={require("../assets/scrollDown.gif")}
                  style={[
                    {
                      width: 50,
                      height: 65,
                    },
                  ]}
                />
              </Pressable>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ModalIsDue;

const tagsStylesM = {
  body: { width: responsiveWidth(85), fontSize: 18 },
};

const styles = StyleSheet.create({
  modalView: {
    marginTop: 200,
    backgroundColor: "#eaf7f8",
    borderRadius: 20,
    marginBottom: 35,
    padding: 15,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  textStyleM: {
    fontSize: 18,
    textAlign: "justify",
    width: responsiveWidth(92),
  },
  buttonM: {
    alignSelf: "center",
    width: 60,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 20,
    paddingHorizontal: 10,
    paddingVertical: 10,
    elevation: 2,
    backgroundColor: "#0344f8",
  },
  textStyleclose: {
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 18,
  },
});
