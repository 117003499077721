import {
  View,
  Text,
  SafeAreaView,
  TextInput,
  Alert,
  Keyboard,
  TouchableOpacity,
} from "react-native";
import React, { useEffect, useState } from "react";
//import { BASE_URL } from "../helpers";
import {BASE_URL_BDLAW, BASE_URL_SIDDIQUE} from '../BaseUrl';
import TopBar from "../TopBar";
import { StylePaymentStatement } from "./StylePaymentStatement";
import { GetUserInfo } from "../../utils/Users";
import AppNet from "../../utils/AppNet";
import StylishButton from "../StylishButton";
import { color2, color3, color4 } from "../../utils/AppColor";
import { responsiveWidth,responsiveHeight } from "react-native-responsive-dimensions";
import IsOffLine from "../IsOffLine";
import { ScrollView } from "react-native-gesture-handler";
import IsLoading from "../IsLoading";
import { clientGet } from "../../utils/rest";
import CardPaymentStatement from "./CardPaymentStatement";
import CardLayarInfoPaymentStatement from "./CardLayarInfoPaymentStatement";

const PaymentStatement = () => {
  // #region variable
  // #endregion variable

  // #region state
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [isConnected, setIsConnected] = useState();
  const [l_id, setL_id] = useState();
  const [modalVisible, setModalVisible] = useState();
  const [selectedItem, setSelectedItem] = useState({});
  const [lawyerInfo, setLawyerInfo] = useState();
  const [paymentInfo, setPaymentInfo] = useState();
  const [apiMessage, setApiMessage] = useState();
  // #endregion state

  // #region redux
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {}, [isConnected]);
  // #endregion redux

  // #region use effect

  // #endregion use effect

  // #region method
  const init = async () => {
    let _userInfo = await GetUserInfo();

    if (_userInfo) {
      setUserInfo(_userInfo);
    }

    let netInfo = await AppNet();
    setIsConnected(netInfo.isConnected);
  };

  const loadPaymenStatementtAction = async (take) => {
    Keyboard.dismiss();

    if (userInfo) {
      setLoading(true);
      setApiMessage("");
      let url = `${BASE_URL_BDLAW}/public/api/getLawyerWithPayments?l_id=${l_id}&page=${take}`;

      let response = await clientGet(url);

      if (response?.status === 200) {
        setLawyerInfo(response.data.lawyerInfo);
        setPaymentInfo([...response.data.paymentInfo]);
        setLoading(false);
      } else {
        setLoading(false);
        setApiMessage(response?.data.message);
      }
    } else {
    }
  };

  // #endregion method
  return (
    <SafeAreaView style={[StylePaymentStatement.container]}>
      <TopBar
        lawyer_id={userInfo?.l_id}
        lawyer_name={userInfo?.lawyer_name}
      ></TopBar>
      <View style={[StylePaymentStatement.searchContainer]}>
        <TextInput
          onChangeText={(e) => {
            setL_id(e);
          }}
          placeholder={"User Code"}
          keyboardType="numeric"
          placeholderTextColor="white"
          autoCorrect={false}
          style={StylePaymentStatement.input}
          selectionColor={"white"}
        />
        <StylishButton
          textColor="#060606"
          colors={color3}
          title="SEARCH"
          width={responsiveWidth(70)}
          style={[StylePaymentStatement.searchButton]}
          onPress={async () => {
            if (!l_id) {
              Alert.alert("Alert", "Please input lawyer id and try again", [
                {
                  text: "OK",
                },
              ]);

              return;
            }

            let netInfo = await AppNet();

            if (!netInfo.isConnected) {
              setIsConnected(false);
              Alert.alert(
                "No internet!", // Title
                "Please connect internet and try again", // Message
                [{ text: "OK" }] // Button
              );

              return;
            } else {
              setIsConnected(true);
            }

            loadPaymenStatementtAction(0);
          }}
        />
      </View>

      {!isConnected ? (
        <View style={[{ flex: 1, justifyContent: "center" }]}>
          <IsOffLine />
        </View>
      ) : (
        <>
          {loading ? (
            <View style={[{ marginTop: -80 }]}>
              <IsLoading />
            </View>
          ) : (
            <>
              {apiMessage != "" ? (
                <View style={[StylePaymentStatement.message]}>
                  <Text style={[StylePaymentStatement.messageText]}>
                    {apiMessage}
                  </Text>
                </View>
              ) : (
                <View style={{ height:responsiveHeight(73),}}>
                  <ScrollView style={[{  width:responsiveWidth(96) }]}>
                    {lawyerInfo && (
                      <CardLayarInfoPaymentStatement lawyerInfo={lawyerInfo} />
                    )}
                    <View
                      style={[{ flex: 1, width: "100%", marginBottom: 35 }]}
                    >
                      {paymentInfo &&
                        paymentInfo.map((item, index) => {
                          return (
                            <CardPaymentStatement key={index} item={item} />
                          );
                        })}

                      {paymentInfo && paymentInfo.length === 10 && (
                        <TouchableOpacity
                          onPress={() => {
                            loadPaymenStatementtAction(1);
                          }}
                          key={"loadPaymenStatementtAction"}
                        >
                          <View>
                            <Text
                              style={{
                                fontSize: 18,
                                textDecorationLine: "underline",
                                color: "#ff0",
                                padding: 10,
                                alignSelf: "center",
                              }}
                            >
                              View All / Last 100
                            </Text>
                          </View>
                        </TouchableOpacity>
                      )}
                    </View>
                  </ScrollView>
                </View>
              )}
            </>
          )}
        </>
      )}
    </SafeAreaView>
  );
};

export default PaymentStatement;
