import { View, Text, Animated } from "react-native";
import React, { useEffect, useState } from "react";
import StyleGlobal from "../style/StyleGlobal";

const IsOffLine = () => {
  // #region variable
  // #endregion

  // #region state
const[fadeAnimation, setFadeAnimation]=useState(new Animated.Value(0))
  // #endregion

  // #region redux

  // #endregion

  // #region use effect
  useEffect(() => {
    Animated.timing(fadeAnimation, {
      toValue: 1,
      duration: 4000,
      useNativeDriver: true,
    }).start();
  });
  // #endregion

  // #region method
  const some = () => {};
  // #endregion
  return (
    <Animated.View
      style={[
        StyleGlobal.fadingContainer,
        {
          opacity: fadeAnimation,
        },
      ]}
    >
      <Text style={StyleGlobal.fadingText}>
        You are currently offline, Please check your internet connection.
      </Text>
    </Animated.View>
  );
};

export default IsOffLine;
