import { View, Text, ActivityIndicator } from "react-native";
import React from "react";

const IsLoading = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: -50,
      }}
    >
      <ActivityIndicator size="large" color="#00ff00" />
    </View>
  );
};

export default IsLoading;
