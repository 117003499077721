import { View, Text, Modal, Pressable, TouchableOpacity, ScrollView } from "react-native";
import React from "react";
import { DateTimeFormat } from "../../utils/AppConstant";
import RenderHtml from "react-native-render-html";
import { responsiveWidth } from "react-native-responsive-dimensions";
import moment from "moment";
import { StyleBillSubmittedMessage } from "./StyleBillSubmittedMessage";
import { lineToBreak } from "../../utils/CommonMethod";
//import ReactNativeZoomableView from "@dudigital/react-native-zoomable-view/src/ReactNativeZoomableView";

const ModalViewBillSubmittedMessage = ({
  modalVisible,
  item,
  onClose = () => {},
}) => {
  // #region variable
  // #endregion

  // #region state

  // #endregion

  // #region redux

  // #endregion

  // #region use effect

  // #endregion

  // #region method
  const some = () => {};
  // #endregion

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        onClose(false);
      }}
    >
      <View
        style={[
          {
            marginHorizontal: 10,
            flex: 1,
            justifyContent: "center",
          },
        ]}
      >
        <View style={StyleBillSubmittedMessage.modalView}>
          <View style={{ flexDirection: "row", width: responsiveWidth(92) }}>
          {/* <ReactNativeZoomableView
                    maxZoom={1.5}
                    minZoom={1}
                    zoomStep={0.5}
                    initialZoom={1}
                    bindToBorders={true}
                    offsetX={1} // current offset left
                    offsetY={1} // current offset top
                    lastX={1.5} // last offset left (before we started the movement)
                    lastY={1} // last offset top (before we started the movement)
                  > */}
            <ScrollView style={[{height: 350}]}>
              <View style={StyleBillSubmittedMessage.textStyleM}>
                <Text style={StyleBillSubmittedMessage.dateText}>
                  {" "}
                  Date & Time: {moment(item.created_at).format(DateTimeFormat)}
                  {"\n"}{" "}
                </Text>
                <Text
                  style={[
                    StyleBillSubmittedMessage.textStyleM,
                    { fontWeight: "bold" },
                  ]}
                >
                  {item.note_head == ""
                    ? item.commomnote.note_head
                    : item.note_head}
                  :- {"\n"}
                </Text>
                <RenderHtml
                  style={[{ marginHorizontal: 20 }]}
                  source={{
                    html:
                      item.notification_text == ""
                        ? lineToBreak(item.commomnote.notification_text || "")
                        : lineToBreak(item.notification_text || ""),
                  }}
                  stylesheet={StyleBillSubmittedMessage}
                  tagsStyles={tagsStylesM}
                  contentWidth={responsiveWidth(92)}
                />
              </View>
            </ScrollView>
            {/* </ReactNativeZoomableView> */}
          </View>
          <TouchableOpacity
            style={[
              StyleBillSubmittedMessage.buttonM,
              StyleBillSubmittedMessage.buttonClose,
            ]}
            onPress={() => {
              onClose(false);
            }}
          >
            <Text style={StyleBillSubmittedMessage.textStyleclose}>Ok</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ModalViewBillSubmittedMessage;

const tagsStylesM = {
  body: { width: responsiveWidth(85), fontSize: 15 },
};
