import { View, Text, Modal, Pressable, TouchableOpacity } from "react-native";
import React from "react";
import { DateTimeFormat } from "../../utils/AppConstant";
import RenderHtml from "react-native-render-html";
import { responsiveWidth } from "react-native-responsive-dimensions";
import moment from "moment";
import { StyleBillPaymentReminder } from "./StyleBillPaymentReminder";
import { lineToBreak } from "../../utils/CommonMethod";

const ModalBillPaymentReminder = ({
  modalVisible,
  item,
  onClose = () => {},
}) => {
  // #region variable
  // #endregion

  // #region state

  // #endregion

  // #region redux

  // #endregion

  // #region use effect

  // #endregion

  // #region method
  const some = () => {};
  // #endregion

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        onClose(false);
      }}
    >
      <View style={[{ marginHorizontal: 10 }]}>
        <View style={StyleBillPaymentReminder.modalView}>
          <View style={{ flexDirection: "row", width: responsiveWidth(92) }}>
            <View style={StyleBillPaymentReminder.textStyleM}>
              <Text style={StyleBillPaymentReminder.dateText}>
                {" "}
                Date & Time: {moment(item.created_at).format(DateTimeFormat)}
                {"\n"}{" "}
              </Text>
              <Text
                style={[
                  StyleBillPaymentReminder.textStyleM,
                  { fontWeight: "bold" },
                ]}
              >
                {item.note_head == ""
                  ? item.commomnote.note_head
                  : item.note_head}
                :- {"\n"}
              </Text>
              <RenderHtml
                style={[{ marginHorizontal: 20 }]}
                source={{
                  html:
                    item.notification_text == ""
                      ? lineToBreak(item.commomnote.notification_text || "")
                      : lineToBreak(item.notification_text || ""),
                }}
                stylesheet={StyleBillPaymentReminder}
                tagsStyles={tagsStylesM}
                contentWidth={responsiveWidth(92)}
              />
            </View>
          </View>
          <TouchableOpacity
            style={[
              StyleBillPaymentReminder.buttonM,
              StyleBillPaymentReminder.buttonClose,
            ]}
            onPress={() => {
              onClose(false);
            }}
          >
            <Text style={StyleBillPaymentReminder.textStyleclose}>Ok</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ModalBillPaymentReminder;

const tagsStylesM = {
  body: { width: responsiveWidth(85), fontSize: 18 },
};
