import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  TouchableOpacity,
} from "react-native";
import React, { useEffect, useState } from "react";
import { StyleBillSubmittedMessage } from "./StyleBillSubmittedMessage";
import { GetUserInfo } from "../../utils/Users";
import IsOffLine from "../IsOffLine";
import IsLoading from "../IsLoading";
//import { BASE_URL } from "../helpers";
import { responsiveWidth,responsiveHeight } from "react-native-responsive-dimensions";
import {BASE_URL_BDLAW, BASE_URL_SIDDIQUE} from '../BaseUrl';
import AppNet from "../../utils/AppNet";
import TopBar from "../TopBar";
import CardBillInfromation from "./CardBillSubmittedMessage";
import { clientGet } from "../../utils/rest";
import AppLog from "../../utils/AppLog";
import CardBillSubmittedMessage from "./CardBillSubmittedMessage";
import ModalViewBillSubmittedMessage from "./ModalViewBillSubmittedMessage";
//import ReactNativeZoomableView from "@dudigital/react-native-zoomable-view/src/ReactNativeZoomableView";

const BillSubmittedMessage = () => {
  // #region variable
  // #endregion variable

  // #region state
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [isConnected, setIsConnected] = useState();
  const [modalVisible, setModalVisible] = useState();
  const [selectedItem, setSelectedItem] = useState({});
  const [bilInformationData, setBilInformationData] = useState();
  // #endregion state

  // #region redux
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    loadBillInformationAction(0);
  }, [isConnected]);
  // #endregion redux

  // #region use effect

  // #endregion use effect

  // #region method
  const init = async () => {
    let _userInfo = await GetUserInfo();

    if (_userInfo) {
      setUserInfo(_userInfo);
    }

    let netInfo = await AppNet();
    setIsConnected(netInfo.isConnected);
  };

  const loadBillInformationAction = async (take) => {
    if (userInfo && isConnected) {
      setLoading(true);
      let url = `${BASE_URL_BDLAW}/public/api/getNotifications_payment_newRo?l_id=${userInfo.l_id}&id=${take}&message_type=Due_Message`;

      let response = await clientGet(url, null);

      if (response?.status === 200) {
        setBilInformationData([...response.data]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
    }
  };

  const viewItemInformation = (item) => {
    setSelectedItem(item);
    // setModalVisible(true);

    if (item.is_view_notification != 1) {
      updateViewStatusAction(userInfo.l_id, item.id, bilInformationData);
    }
  };

  const updateViewStatusAction = async (l_id, id, oldData) => {
    let netInfo = await AppNet();

    if (!netInfo.isConnected) {
      return;
    }

    const itemToUpdate = oldData.find((item) => item.id === id);

    if (itemToUpdate) {
      itemToUpdate.is_view_notification = 1; // Update the name here
    }

    setBilInformationData([...oldData]);

    let url = `${BASE_URL_BDLAW}/public/api/getNotifications_payment_value_update?l_id=${l_id}&id=${id}`;
    let response = await clientGet(url, null);

    if (response?.status === 200) {
    } else {
    }
  };
  // #endregion method
  return (
    <SafeAreaView style={[StyleBillSubmittedMessage.container]}>
      <TopBar
        lawyer_id={userInfo?.l_id}
        lawyer_name={userInfo?.lawyer_name}
      ></TopBar>

      <View
        style={[
          StyleBillSubmittedMessage.container,
          { justifyContent: "center" },
        ]}
      >
        {!isConnected ? (
          <IsOffLine />
        ) : loading ? (
          <IsLoading />
        ) : (
          <View style={[{ height:responsiveHeight(90), width: "100%" }]}>

            {bilInformationData && bilInformationData.length > 0 && (
             
                <FlatList
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  data={bilInformationData}
                  renderItem={({ item, index }) => (
                    <CardBillSubmittedMessage
                      key={index}
                      item={item}
                      onPress={viewItemInformation}
                    />
                  )}
                  keyExtractor={(item) => item.id.toString()}
                  ListFooterComponent={() => {
                    return (
                      <View style={[{ marginBottom: 60 }]}>
                        {bilInformationData &&
                        bilInformationData.length === 10 ? (
                          <TouchableOpacity
                            onPress={() => {
                              loadBillInformationAction(userInfo.l_id, 1);
                            }}
                            key={"loadBillInformationAction"}
                          >
                            <View>
                              <Text
                                style={{
                                  fontSize: 18,
                                  textDecorationLine: "underline",
                                  color: "#ff0",
                                  padding: 10,
                                  alignSelf: "center",
                                }}
                              >
                                View All / Last 100 Message
                              </Text>
                            </View>
                          </TouchableOpacity>
                        ) : null}
                      </View>
                    );
                  }}
                />
             
            )}

            {bilInformationData && bilInformationData.length == 0 && (
              <View
                style={[
                  {
                    flex: 1,
                    justifyContent: "center",
                  },
                ]}
              >
                <View style={[StyleBillSubmittedMessage.hddatamaintwo]}>
                  <Text style={{ textAlign: "center", fontSize: 20 }}>
                    Bill submit message not found.
                  </Text>
                </View>
              </View>
            )}
          </View>
        )}
      </View>
      {modalVisible && (
        <ModalViewBillSubmittedMessage
          modalVisible={modalVisible}
          item={selectedItem}
          onClose={setModalVisible}
        />
      )}
    </SafeAreaView>
  );
};

export default BillSubmittedMessage;
