import { StyleSheet } from "react-native";


const StyleGlobal = StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    flex: 1,
  },
  fadingContainer: {
    backgroundColor: "blueviolet",
    borderRadius: 4,
    marginTop: -50,
    margin: 20,
  },
  fadingText: {
    fontSize: 16,
    textAlign: "center",
    color: "#fff",
    paddingVertical: 5,
    paddingHorizontal: 25,
  },
});
export default StyleGlobal