import { View, Text, TouchableOpacity } from "react-native";
import React from "react";
import { responsiveWidth } from "react-native-responsive-dimensions";
import moment from "moment";
import { DateTimeFormat } from "../../utils/AppConstant";
import RenderHtml from "react-native-render-html";
import { StyleBillPaymentReminder } from "./StyleBillPaymentReminder";
import { lineToBreak } from "../../utils/CommonMethod";

const CardBillPaymentReminder = ({ item, onPress = () => {} }) => {
  // #region variable
  // #endregion

  // #region state

  // #endregion

  // #region redux

  // #endregion

  // #region use effect

  // #endregion

  // #region method
  const some = () => {};
  // #endregion

  return (
    <TouchableOpacity
      style={[
        item.is_view_notification == 1
          ? StyleBillPaymentReminder.hddata
          : StyleBillPaymentReminder.hddatanotview,
        {
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          width: responsiveWidth(95),
        },
      ]}
      onPress={() => {
        onPress(item);
        //   this.setState({ modalitem: item });
        //   this.setState({ modalVisible: true });
        //   this.viewpaymentdata(item.id);
      }}
    >
      <View
        activeOpacity={item.is_view_notification == 1 ? 1 : 0.2}
        style={{
          flexDirection: "row",
          paddingLeft: 5,
          width: responsiveWidth(92),
        }}
      >
        <View style={StyleBillPaymentReminder.textStyle}>
          <Text style={StyleBillPaymentReminder.dateText}>
            {" "}
            Date & Time: {moment(item.created_at).format(DateTimeFormat)}
            {"\n"}{" "}
          </Text>
          <Text
            style={[StyleBillPaymentReminder.textStyle, { fontWeight: "bold", marginBottom: 0}]}
          >
            {item.note_head == "" ? item.commomnote.note_head : item.note_head}
            :- {"\n"}
          </Text>
          <RenderHtml
            source={{
              html:
                item.notification_text == ""
                  ? lineToBreak(item.commomnote.notification_text || "")
                  : lineToBreak(item.notification_text || ""),
            }}
            stylesheet={StyleBillPaymentReminder}
            tagsStyles={tagsStyles}
            contentWidth={responsiveWidth(100)}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default CardBillPaymentReminder;

const tagsStyles = {
  h1: {
    color: "#6728C7",
    textAlign: "center",
    marginBottom: -5,
  },
  body: { width: responsiveWidth(91) },
  img: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
  },
  p: {
    textAlign: "justify",
    width: responsiveWidth(90),
    marginTop: -10,
  },
  li: {
    textAlign: "justify",
    width: responsiveWidth(90),
  },
  span: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: -10,
  },
};
