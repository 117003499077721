import { View, Text, Alert } from "react-native";
import React from "react";
import { StylePaymentStatement } from "./StylePaymentStatement";
import { responsiveFontSize } from "react-native-responsive-dimensions";
import { TouchableOpacity } from "react-native-gesture-handler";
import * as Linking from "expo-linking";

const CardLayarInfoPaymentStatement = ({ lawyerInfo }) => {
  // #region variable

  // #endregion variable

  // #region state

  // #endregion state

  // #region redux

  // #endregion redux

  // #region use effect

  // #endregion use effect

  // #region method
  const makeCall = (contact) => {
    contact = contact.replace(/-/g, "");

    // Define the regex pattern to find the number after "SMS"
   // const regex = /(?<=SMS\s)\d{11}/;
const regex = /SMS\s(\d{11})/;
const match = contact.match(regex);
const result = match ? match[1] : null;
    // Use the match method to extract the number
   // const result = contact.match(regex);

    if(result){
      Linking.openURL(`tel:${result}`);
    }else{
      Alert.alert(
        "Alert", // Title
        "SMS contact not found!", // Message
        [{ text: "OK" }] // Button
      );
    }
    
  };
  // #endregion method
  return (
    <View style={[StylePaymentStatement.lawyerinfo]}>
      <View style={[StylePaymentStatement.lawyerInfoRow]}>
        <Text style={[StylePaymentStatement.lawyerinfoTextLabel]}>
          <Text
            numberOfLines={2}
            style={[
              StylePaymentStatement.lawyerinfoTextValue,
              { fontWeight: "bold", fontSize: responsiveFontSize(1.9) },
            ]}
          >
            User Code : {lawyerInfo.lawyer_id}
          </Text>
        </Text>
      </View>

      <View style={[StylePaymentStatement.lawyerInfoRow]}>
        <Text style={[StylePaymentStatement.lawyerinfoTextLabel]}>
          <Text
            numberOfLines={2}
            style={[
              StylePaymentStatement.lawyerinfoTextValue,
              { fontWeight: "bold", fontSize: responsiveFontSize(1.9) },
            ]}
          >
            {lawyerInfo.lawyer_name}
          </Text>
        </Text>
      </View>

      <TouchableOpacity
        onPress={() => {
          makeCall(lawyerInfo.phone);
        }}
        style={[StylePaymentStatement.lawyerInfoRow]}
      >
        <Text style={[StylePaymentStatement.lawyerinfoTextLabel]}>
          <Text style={[StylePaymentStatement.lawyerinfoTextValue]}>
            {lawyerInfo.phone}
          </Text>
        </Text>
      </TouchableOpacity>

      <View style={[StylePaymentStatement.lawyerInfoRow]}>
        <Text style={[StylePaymentStatement.lawyerinfoTextLabel]}>
          <Text style={[StylePaymentStatement.lawyerinfoTextValue]}>
            {lawyerInfo.address}
          </Text>
        </Text>
      </View>
    </View>
  );
};

export default CardLayarInfoPaymentStatement;
