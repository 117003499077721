import { View, Text } from "react-native";
import React from "react";
import { StylePaymentStatement } from "./StylePaymentStatement";
import moment from "moment";
import { DateTimeFormat, displayFormat } from "../../utils/AppConstant";
import RenderHtml from "react-native-render-html";
import { responsiveWidth } from "react-native-responsive-dimensions";

const CardPaymentStatement = ({ item }) => {
  return (
    <View style={[StylePaymentStatement.infoCard]}>
      <Text style={StylePaymentStatement.dateText}>
        {" "}
        Date & Time: {moment(item.created_at).format(DateTimeFormat)}
        {"\n"}{" "}
      </Text>
      <Text style={[StylePaymentStatement.textStyle, { fontWeight: "bold" }]}>
        {item.note_head == ""
          ? item.commomnote.note_head == "Bill Submit"
            ? "Search List Bill Submit"
            : item.commomnote.note_head
          : item.note_head == "Bill Submit"
          ? "Search List Bill Submit"
          : item.note_head}
        - {"\n"}
      </Text>
      <RenderHtml
        source={{
          html:
            item.notification_text == ""
              ? item.commomnote.notification_text
              : item.notification_text,
        }}
        contentWidth={responsiveWidth(100)}
      />
    </View>
  );
};

export default CardPaymentStatement;
