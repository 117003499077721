import React, { useState } from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Animated } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

//const Colors1 = ThemeTwo;
const StylishButton = (props) => {
 
  const Tcolor = props.textColor?props.textColor:"#050505"; 
 
  const color =props.colors?props.colors: ["#d8bdf5","#b579f5","#943bf3" ];
  const w = props.width?props.width:140

  //const lawyerName = props.lawyer_name; 
  
  const [scaleValue] = useState(new Animated.Value(1));

  const onPressIn = () => {
    Animated.spring(scaleValue, {
      toValue: 0.95,
      useNativeDriver: true,
    }).start();
  };

  const onPressOut = () => {
    Animated.spring(scaleValue, {
      toValue: 1,
      friction: 4,
      tension: 160,
      useNativeDriver: true,
    }).start();
  };

  return (
    <View>
      <Animated.View style={{ transform: [{ scale: scaleValue }] }}>
        <TouchableOpacity
          activeOpacity={0.8}
          onPressIn={onPressIn}
          onPressOut={onPressOut}
          style={[styles.button,{width:w}]}
          onPress={props.onPress}
          {...props}
        >
          <LinearGradient
            colors={color}
            //start={[0, 0]}
           // end={[1, 1]}
            style={styles.gradient}
                      
           //style={{ ...StyleSheet.absoluteFillObject }} 
          >
            <Text style={[styles.buttonText,{color:Tcolor}]}>{props.title}</Text>
          </LinearGradient>
        </TouchableOpacity>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
   // justifyContent: 'center',
   // alignItems: 'center',
   // marginLeft:20
  //  backgroundColor: '#f0f0f0',
 
  },
  button: {
    borderRadius: 30,
    elevation: 5, // Shadow for Android
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    minWidth:60
  },
  gradient: {
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderRadius: 8,
  },
  buttonText: {
   
    fontSize: 18,
    elevation: 6,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default StylishButton;
